import React from 'react'

import Layout from '../components/layout'
import EthicOne from '../components/strategy/EthicOne'


const Work = props => (
  <Layout lang="pt" title="Código de Ética e Conduta" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <EthicOne lang="pt" />
  </Layout>
)

export default Work
